import styled from 'styled-components';
import {Link} from 'react-router-dom';

const CardStyled = styled.div`
/* height: 100px; */
/* width: 30%; */
/* border-radius: 30px; */
/* background-color: pink; */
/* margin: 20px 20px; */
/* display: inline-block; */
/* justify-content: flex-start; */
/* align-items: flex-start; */
/* text-decoration: none; */
/* flex-wrap: wrap; */
/* flex-direction: row; */
`

const StyledLink = styled(Link)`
    text-decoration: none;
    display: inline-block;
    height: 150px;
    width: 45%;
    margin-bottom: 20px;
    margin-left: 5px;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`;

const Card = (props) => {
    return (
        <>
        <StyledLink to={'article/' + props.article.id} >
        {props.article.title}
     </StyledLink>
     </>
    )
}

export default Card;