import AboutMe from './container/AboutMe';
import Home from './container/Home';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import Article from './container/Article';
const AppStyle = styled.div`
height: 100%;
width: 80%;
display: flex;
justify-content: center;
align-items: center;
`

function App() {
  return (
    <AppStyle>
      <Routes>
        <Route path='/' element={<Home />}> </Route>
        <Route path='/about' element={<AboutMe />}></Route>
        <Route path='/article'>
          <Route path=':articleId' element={<Article/>}>

          </Route>
        </Route>
      </Routes>
    </AppStyle>
  );
}

export default App;
