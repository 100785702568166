import styled from 'styled-components';
import Card from './Card';
const CardContainerStyled = styled.div`
/* background-color: #F5F5F5; */
display: flex;
height: 100%;
width: 100%;
flex-wrap: wrap;
`

export default function CardContainer(props) {
    const cardList = props.articles.map(article => <Card article={article}/>);
    return <CardContainerStyled>
        {cardList}
    </CardContainerStyled>
}   