
import CardContainer from "./CardContainer";
import SideBar from "./SideBar";
import styled from 'styled-components';
import React, { useEffect, useState } from "react";

const MainStyle = styled.div`
display: flex;
/* justify-content: space-between; */
/* align-items: center; */
`

export default function Main() {
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        fetch("https://of4ifl2l2f.execute-api.ap-south-1.amazonaws.com/api/articles")
        .then(response => {
            return response.json()
        })
        .then(data => {
            setArticles(data)
        })
    }, [])
    return <MainStyle>
        <SideBar articles={articles} />
        <CardContainer articles={articles} />
    </MainStyle>
}   