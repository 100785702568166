import styled from 'styled-components';
import NavItem from './NavItem';
import { NavLink } from 'react-router-dom';

const HeaderStyles = styled.div`
display: flex;
background-color: #F5F5F5;
height: 50px;
width: 100%;
justify-content: center;
align-items: center;
`

const navLinkStyles =  ({isActive}) => {
    return {
        fontWeight: isActive ? 'bold': 'normal',
        textDecoration: isActive ? 'underline': 'none'
    }
}

export default function Header() {



    return (<HeaderStyles>
        <NavLink to='/' style={navLinkStyles}><NavItem> Home</NavItem></NavLink>
        <NavLink to='/about' style={navLinkStyles}> <NavItem> About Me</NavItem> </NavLink>
         </HeaderStyles>)
}   