import styled from 'styled-components';

const SideBarStyled = styled.div`
display: flex;
width: 15%;
/* background-color: #F5F5F5; */
justify-content: stretch;
align-items: center;
height: 100%;
border-right: 1px solid ;
flex-direction: column;
`

const SideBar = (props) => {
    const categories = props.articles.map(element => element.categories);
    const categoryListFinal = [];
    for(var categoryList in categories ) {
        var individualCategoryList = categories[categoryList];
        console.log(individualCategoryList);
        for(var cat in individualCategoryList) {
            console.log(individualCategoryList[cat]);
            categoryListFinal.push(<p> {individualCategoryList[cat]}</p>);
        }
        
    }
    console.log(categoryListFinal);
    return <SideBarStyled>
        {categoryListFinal}
    </SideBarStyled>
};

export default SideBar;