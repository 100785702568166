
import Header from './Header';
import Main from './Main';


function Home() {
  return (
      <div>
        <Header />
        <Main />
      </div>
  );
}

export default Home;
