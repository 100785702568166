import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from 'styled-components';

function Article(props) {
    const params = useParams();
    const articleId = params.articleId;
    const [articleDetails, setArticleDetails] = useState('');
    const [title, setTitle] = useState('');
    useEffect(() => {
        fetch("https://of4ifl2l2f.execute-api.ap-south-1.amazonaws.com/api/articles/" + articleId)
        .then(response => {
            return response.json()
        })
        .then(data => {
            setArticleDetails(data.content);
            setTitle(data.title);
        })
    }, [title, articleDetails, articleId]);

    const TitleStyled = styled.div`
    font-weight: 700;
    margin: 5rem 5rem;
    `;
    return (
        <div>
            <TitleStyled>{title}</TitleStyled>
            {articleDetails}
        </div>
    );
}

export default Article;